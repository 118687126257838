
import { useEventsStore } from "@/stores/events"
import { mapStores } from "pinia"

export default {
  name: "RecordingsPlayerRoiOverlay",
  props: {
    label: {
      type: String,
      default: "",
    },
    roi: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapStores(useEventsStore),
    roiStyle() {
      const [[x1, y1], [x2, y2]] = this.roi?.shapes?.find(
        (s) => s.type === "rectangle"
      )?.coordinates || [[], []]

      if (!(x1 && y1 && x2 && y2)) {
        return {}
      }

      return {
        top: `${y1 * 100}%`,
        left: `${x1 * 100}%`,
        height: `${(y2 - y1) * 100}%`,
        width: `${(x2 - x1) * 100}%`,
      }
    },
    isActive() {
      const isOldEventSchema =
        this.eventsStore.activeEvent?.id && !this.eventsStore.activeEvent.roiId

      return (
        this.eventsStore.activeEvent.roiId === this.roi.id ||
        isOldEventSchema ||
        this.eventsStore.activeRoi?.id === this.roi.id
      )
    },
  },
}


import RightSidebarContent from "@/components/portals/RightSidebarContent"
import RecordingsSidebar from "@/components/recordings/RecordingsSidebar"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar"
import RecordingsPlayerXrayOverlay from "@/components/recordings/player/RecordingsPlayerXrayOverlay"
import SnapshotEditor from "@/components/SnapshotEditor"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons"
import PtzBox from "@/components/ptz/PtzBox"

import { updateQuery } from "@evercam/shared/utils"
import Vue from "vue"
import { useSnapshotStore } from "@/stores/snapshots"
import { RecordingsTabs, useRecordingsStore } from "@/stores/recordings"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useEventsStore } from "@/stores/events"
import { useWeatherStore } from "@/stores/weather"
import { useConnectorStore } from "@/stores/connector"
import { useProjectStore } from "@/stores/project"

import type { RouteParams } from "@evercam/shared/types/routeParams"
import { camelizeKeys } from "humps"
import { Context } from "@nuxt/types"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { StyleValue } from "vue"
import { CameraFeatureFlag } from "@evercam/shared/types"

export default Vue.extend({
  name: "TimestampRecording",
  meta: {
    pageId: AnalyticsEventPageId.Recordings,
  },
  components: {
    EvercamPlayer,
    RecordingsSidebar,
    RightSidebarContent,
    CameraStatusSnackbar,
    RecordingsPlayerXrayOverlay,
    SnapshotEditor,
    PlayersActionButtons,
    PtzBox,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, query, app, route }: Context): Promise<void> {
    const snapshotStore = useSnapshotStore()
    const recordingsStore = useRecordingsStore()
    const cameraStore = useCameraStore()
    let { cameraExid, timestamp } = camelizeKeys(route.params) as RouteParams
    if (!app.$permissions.user.can.access.recordings()) {
      // @ts-ignore
      return redirect(cameraStore.cameraRoute)
    }

    const isTimestampInvalid = !timestamp || !app.$moment(timestamp).isValid()

    const shouldUpdateSnapshots =
      isTimestampInvalid ||
      !snapshotStore.cameraExid ||
      snapshotStore.cameraExid !== cameraExid ||
      !snapshotStore.latestSnapshotTimestamp ||
      !snapshotStore.oldestSnapshotTimestamp ||
      !app.$moment(snapshotStore.latestSnapshotTimestamp).isValid() ||
      !app.$moment(snapshotStore.oldestSnapshotTimestamp).isValid()

    if (shouldUpdateSnapshots) {
      await snapshotStore.updateLatestAndOldestSnapshots(cameraExid)
    }

    if (isTimestampInvalid) {
      timestamp = snapshotStore.latestSnapshotTimestamp
    }

    recordingsStore.selectedTimestamp = timestamp as string
    const isEdgeVideo =
      query.video === "true" && app.$permissions.camera.has.edgeVideoStreaming()

    if (isEdgeVideo) {
      await recordingsStore.createEdgeStreamingSession({
        cameraExid: cameraStore.selectedCamera?.id,
        token: useAccountStore().token,
      })
      await recordingsStore.fetchExNvrAvailableRecordings()
    }

    recordingsStore.isLive = query.live === "true"
    recordingsStore.isEdgeVideo = isEdgeVideo
  },
  data() {
    return {
      playerDimensions: {} as { width: number; height: number },
      xrayImageProps: {},
      editorTranslation: 0,
      playerTimestamp: null,
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.recordings"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useSnapshotStore,
      useLayoutStore,
      useCameraStore,
      useAccountStore,
      useEventsStore,
      useWeatherStore,
      useConnectorStore,
      useProjectStore
    ),
    snapshotsInterval() {
      return {
        from: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .startOf("hour")
          .utc()
          .format(),
        to: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .endOf("hour")
          .utc()
          .format(),
      }
    },
    isZoomable() {
      return this.$device.isMobile ? !this.recordingsStore.isXrayActive : true
    },
    placeholderImage() {
      let timestamp = this.$moment(this.recordingsStore.selectedTimestamp)
        .tz(this.cameraStore.selectedCamera.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      if (timestamp === this.snapshotStore.oldestSnapshotTimestamp) {
        return this.snapshotStore.oldestSnapshot.data
      }

      if (timestamp === this.snapshotStore.latestSnapshotTimestamp) {
        return this.snapshotStore.latestSnapshot.data
      }

      return ""
    },
    editorTranslationStyles() {
      return {
        transform: `translate(0, ${this.editorTranslation}px)`,
      }
    },
    hasLiveView(): boolean {
      return (
        this.cameraStore.hasCameraLiveView ||
        this.$permissions.camera.has.edgeVideoStreaming()
      )
    },
    sidebarStyle(): StyleValue {
      if (window.innerWidth > 960) {
        return {
          maxHeight: `${this.playerDimensions.height}px`,
          height: `${this.playerDimensions.height}px`,
        }
      } else {
        return {
          maxHeight: "100%",
          height: "100%",
        }
      }
    },
    timezone(): string {
      return this.cameraStore.selectedCameraTimezone
    },
    canShowSidebar(): boolean {
      return !this.recordingsStore.isEditing
    },
    isBrainToolActive(): boolean {
      return this.recordingsStore.selectedTab === RecordingsTabs.BrainTool
    },
    isPtzBoxEnabled(): boolean {
      return (
        this.cameraStore.selectedCamera?.featureFlags.includes(
          CameraFeatureFlag.PTZ
        ) &&
        this.cameraStore.isCameraOnline &&
        !this.accountStore.isLiveViewWidget &&
        !!this.accountStore.token
      )
    },
  },
  watch: {
    "layoutStore.isRightSidebarVisible": "updatePlayerDimensions",
    "layoutStore.rightSidebarWidth": "updatePlayerDimensions",
    "layoutStore.isFullscreen": "updatePlayerDimensions",
    "cameraStore.isCameraTab": "updatePlayerDimensions",
    "recordingsStore.isEditing"(v) {
      this.$setTimeout(this.updatePlayerDimensions)
      const translationStart = v ? -this.layoutStore.footerRect.height : 0
      const translationEnd = v ? 0 : -this.layoutStore.footerRect.height

      this.editorTranslation = translationStart
      this.$nextTick(() => {
        this.$setTimeout(() => {
          this.editorTranslation = translationEnd
        })
      })
    },

    "recordingsStore.selectedTimestamp"(value, oldValue) {
      if (value && value !== oldValue) {
        this.updateUrlTimestamp(value)
      }
    },
    "eventsStore.selectedEvent"(event = {}) {
      if (
        !event.snapshotTimestamp ||
        event.snapshotTimestamp === this.recordingsStore.selectedTimestamp
      ) {
        return
      }

      // this.recordingsStore.selectedTimestamp = event.snapshotTimestamp
    },
    "recordingsStore.isLive": {
      handler(value) {
        this.$nextTick(() => {
          this.layoutStore.enableRightSidebar()
        })
        updateQuery({ live: value || undefined })
      },
      immediate: true,
      deep: true,
    },
    "recordingsStore.isEdgeVideo": {
      handler(value) {
        updateQuery({ video: value || undefined })
      },
      immediate: true,
      deep: true,
    },
    canShowSidebar(value) {
      if (value) {
        this.layoutStore.enableRightSidebar()
      } else {
        this.layoutStore.disableRightSidebar()
      }
    },
  },
  mounted() {
    this.layoutStore.fullscreenElement = this.$globalRefs.recordingContainer
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.$nextTick(this.updatePlayerDimensions)
    this.$addEventListener("orientationchange", this.handleOrientationChange)
    this.layoutStore.enableRightSidebar({ isResizable: true })
    this.$registerInactivityListener({
      callback: this.onIdle,
      duration: 120,
      reminders: [110],
    })
  },
  beforeDestroy() {
    this.recordingsStore.reset()
    this.layoutStore.disableRightSidebar()
  },
  methods: {
    toggleIosFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    async onIdle() {
      if (!this.recordingsStore.isLive) {
        return
      }

      this.recordingsStore.isLive = false

      if (this.weatherStore.dialog || this.recordingsStore.isEditing) {
        return
      }

      const userConfirmed = await this.$confirmDialog.open({
        title: this.$t("content.live_view.pause_live_view"),
        message: this.$t("content.live_view.inactive_live_view"),
        options: {
          width: 360,
          confirmText: this.$t("actions.resume"),
        },
      })

      if (userConfirmed) {
        this.recordingsStore.isLive = true
      }
    },
    handleOrientationChange() {
      this.$setTimeout(this.updatePlayerDimensions, 100)
    },
    updatePlayerDimensions() {
      if (this.recordingsStore.isEditing) {
        return
      }
      const cameraTabsHeight =
        this.$globalRefs.cameraTabs?.getBoundingClientRect()?.height || 0
      const appBarHeight =
        this.$globalRefs.appBar?.getBoundingClientRect()?.height || 0
      const leftSideBarWidth =
        this.$globalRefs.sideBar?.getBoundingClientRect()?.width || 0
      const rightSidebarWidth = this.layoutStore.isRightSidebarVisible
        ? this.$globalRefs.rightSidebar?.getBoundingClientRect()?.width || 0
        : 0
      const topBarsHeight = cameraTabsHeight + appBarHeight

      this.playerDimensions = {
        width: window.innerWidth,
        height: window.innerHeight - topBarsHeight,
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.playerDimensions = {
          ...this.playerDimensions,
          width: window.innerWidth - rightSidebarWidth - leftSideBarWidth,
        }
      }
    },
    updateUrlTimestamp(timestamp) {
      let selectedTimestamp = this.$moment(timestamp)
        .tz(this.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      history.replaceState(
        {},
        null,
        [
          ...window.location.href.split("/").slice(0, -1),
          selectedTimestamp,
        ].join("/")
      )
      this.$route.params.timestamp = selectedTimestamp

      updateQuery({
        live: (this.recordingsStore.isLive || "") as string,
        video: `${this.recordingsStore.isEdgeVideo}` || undefined,
      })
    },
    onImagePropsChange(props) {
      this.xrayImageProps = props
    },
  },
})


import RecordingsPlayerRoiOverlay from "@/components/recordings/player/RecordingsPlayerRoiOverlay"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import { useRecordingsStore } from "@/stores/recordings"
import { useEventsStore } from "@/stores/events"
import { useCameraStore } from "@/stores/camera"

export default {
  name: "RecordingsEventsList",
  components: {
    RecordingsPlayerRoiOverlay,
  },
  computed: {
    ...mapStores(
      useGateReportStore,
      useRecordingsStore,
      useEventsStore,
      useCameraStore
    ),
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    uniqueRois() {
      return this.gateReportStore.rois.reduce((acc, roi) => {
        return acc.find((r) => r.name === roi.name) ? acc : [...acc, roi]
      }, [])
    },
    activeEvent() {
      return this.eventsStore.filteredEvents.find((e) => e.isActive) || {}
    },
  },
  methods: {
    onEventMouseEnter(event) {
      this.eventsStore.filteredEvents = this.eventsStore.filteredEvents.map(
        (e) => {
          return {
            ...e,
            isActive: e?.id === event?.id,
          }
        }
      )
    },
    onEventMouseLeave() {
      this.eventsStore.filteredEvents = this.eventsStore.filteredEvents.map(
        (e) => {
          return {
            ...e,
            isActive: false,
          }
        }
      )
    },
    onEventMouseUp(event) {
      this.eventsStore.selectedEvent = event
    },
    getProcessedTimestamp(timestamp) {
      return this.$moment.tz(timestamp, this.timezone).format("HH:mm:ss")
    },
    getROIName(roi = {}) {
      let targetRoi = roi
      if (Number.parseInt(roi)) {
        targetRoi = this.gateReportStore.rois.find((r) => r.id === roi) || {
          name: "",
        }
      }
      const name = targetRoi.name

      if (!name) {
        return ""
      }

      return `${name[0].toUpperCase()}${name.slice(1).replace(/[_-]/g, " ")}`
    },
    isRoiVisible(roi) {
      return (
        this.gateReportStore.roisVisibility[roi.id] &&
        (this.eventsStore.activeRoi.id === roi.id ||
          this.activeEvent.roiId === roi.id ||
          this.activeEvent.roiId === null)
      )
    },
  },
}

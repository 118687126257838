
import { PtzApi } from "@evercam/shared/api/ptzApi"

export default {
  props: {
    preset: {
      type: Object,
      default: () => ({}),
    },
    cameraExid: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      presetName: "",
    }
  },
  computed: {
    isPresetCreation() {
      return !this.preset.name
    },
  },
  mounted() {
    this.presetName = this.preset.name || ""
  },
  methods: {
    close(update = false) {
      this.$emit("close", update)
    },
    async deletePreset(cameraExid, presetId) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.confirmation"),
          message: this.$t("content.ptz.preset_delete_confirmation", {
            presetName: this.preset.name,
          }),
          options: { width: 360 },
        })
      ) {
        try {
          await PtzApi.presets.delete({
            cameraExid,
            presetId,
          })
          this.$notifications.success(this.$t("content.ptz.preset_deleted"))
        } catch (error) {
          console.log(error)
        }
        this.close(true)
      }
    },
    async savePreset() {
      if (this.isPresetCreation) {
        await this.createPreset(this.cameraExid, this.presetName)
      } else {
        await this.updatePreset(
          this.cameraExid,
          this.presetName,
          this.preset.id
        )
      }
      this.close(true)
    },
    async updatePreset(cameraExid, presetName, presetId) {
      try {
        await PtzApi.presets.update({
          cameraExid,
          presetId,
          presetName,
        })
        this.$notifications.success(this.$t("content.ptz.preset_updated"))
      } catch (error) {
        console.log(error)
      }
    },
    async createPreset(cameraExid, presetName) {
      try {
        await PtzApi.presets.create({
          cameraExid,
          presetName,
        })
        this.$notifications.success(this.$t("content.ptz.preset_created"))
      } catch (error) {
        console.log(error)
      }
    },
  },
}


import { PtzApi } from "@evercam/shared/api/ptzApi"
import PtzPresetForm from "@/components/ptz/PtzPresetForm"

export default {
  name: "PtzPresetItem",
  components: {
    PtzPresetForm,
  },
  props: {
    preset: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEditing: false,
    }
  },
  computed: {
    isHomePreset() {
      return this.preset.id === "home"
    },
    presetIcon() {
      let presetIcon
      if (this.isHomePreset) {
        presetIcon = "fas fa-home"
      } else {
        presetIcon = this.$device.isIos
          ? "fa-location-crosshairs"
          : "fa-arrows-to-dot"
      }

      return presetIcon
    },
  },
  watch: {
    isActive(val) {
      if (!val) {
        this.isEditing = false
      }
    },
  },
  methods: {
    closeForm(update = false) {
      this.isEditing = false
      if (update) {
        this.$emit("update")
      }
    },
    editPreset() {
      this.selectPreset()
      this.isEditing = true
    },
    async selectPreset() {
      this.$emit("preset-selected")

      if (this.isHomePreset) {
        this.selectHomePreset()

        return
      }
      try {
        await PtzApi.presets.go({
          cameraExid: this.camera.id,
          presetId: this.preset.id,
        })

        if (!this.isEditing) {
          this.$notifications.success(this.$t("content.ptz.location_updated"))
        }
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.ptz.preset_applied_failed"),
          error,
        })
      }
    },
    async selectHomePreset() {
      try {
        await PtzApi.presets.home({
          cameraExid: this.camera.id,
          isRecordingFromNvr: this.camera.recordingFromNvr,
        })

        this.$notifications.success(this.$t("content.ptz.location_updated"))
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.ptz.preset_applied_failed"),
          error,
        })
      }
    },
  },
}

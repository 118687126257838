
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
export default {
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useLayoutStore),
  },
}
